import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { ProductCard } from '../components/ProductCard';
// import { fetchArticlesByFilter } from '../Macrosoft_API';
// import { fetchImagesByArticle } from './controller/imageController';
import { fetchGetHighlightProducts } from './controller/productosDestacadosController';

export const ProductsCatalog = () => {
	const [products, setProducts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	// const [images, setImages] = useState({});

	const fetchData = async () => {
		try {
			const response = await fetchGetHighlightProducts();
			const data = response.data;
			setProducts(data);
			console.log('Productos destacados ACTUALES: ', data);

			setLoading(false);
			return products;
		} catch (error) {
			setError(error);
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error loading products: {error.message}</div>;

	return (
		<>
			<Grid
				container
				sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
			>
				{products.length > 0 ? (
					products.map((product, index) => {
						const imageUrl = product.CodArticulo || 'default-image-url.jpg';
						return (
							<Grid
								className='GRIDDDDDD*****'
								item
								key={index}
								xs={12}
								sm={6}
								md={4}
								lg={2}
								sx={{ display: 'flex', justifyContent: 'center', margin: 6 }}
							>
								<ProductCard
									product={product}
									imageUrl={imageUrl}
								/>
							</Grid>
						);
					})
				) : (
					<p>No products available</p>
				)}
			</Grid>
		</>
	);
};
