import React, { useState, useEffect } from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Button from "@mui/joy/Button";
import { TextField, Box } from "@mui/material";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Typography from "@mui/joy/Typography";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/system";
import EditProductModal from "./utils/EditProduct";
import useMainContext from "./Context/useMainContext";
import { fetchImagesByArticle } from "./controller/imageController";
import { fetchImages } from "../Macrosoft_API";
import ImageIcon from "@mui/icons-material/Image";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const WhiteCheckbox = styled(Checkbox)({
  color: "white",
  "&.Mui-checked": {
    color: "white",
  },
});

const currency = "U$S";

export function ProductCard({ product, imageUrl }) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { arrayProductShop, setArrayProductShop, CodCliente } =
    useMainContext();
  const [checked, setChecked] = useState(false);
  const [image, setImage] = useState(null);
  const [imageLoad, setImageLoad] = useState(null);
  const [open, setOpen] = useState(false);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [family, setfamily] = useState(0);
  const [cant, setCant] = useState(0);
  // cerrar el modal
  const handleCloseModalProduct = () => {
    setOpen(false);
  };

  const handleCardClick = (e) => {
    if (!e.target.closest("button") && !e.target.closest(".MuiCheckbox-root")) {
      setOpen(true);
    }
  };
  const handleQuantityChange = (productId, newQuantity) => {
    // if (newQuantity < 1) return; // Evita valores menores a 1
    setArrayProductShop((prevArray) =>
      prevArray?.map((product) =>
        product.CodArticulo === productId
          ? { ...product, quantity: newQuantity }
          : product
      )
    );
  };
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleCheckboxClick = (e) => {
    e.stopPropagation();
    setChecked(!checked);
  };

  const handleAddProductCard = () => {
    const newArrayProduct = [...arrayProductShop];
    newArrayProduct.push(product);
    setArrayProductShop(newArrayProduct);
  };

  const removeProductFromCart = (productId) => {
    const newArrayProduct = arrayProductShop.filter(
      (item) => item.CodArticulo !== productId
    );
    setArrayProductShop(newArrayProduct);
  };

  const handleFetchImages = async (name) => {
    try {
      const response = await fetchImages(name);
      setImageLoad(response);
    } catch (error) {
      // console.log('hubo un error en fetch IMAGES: ', error);
    }
  };

  useEffect(() => {
    product.quantity = 1;
    if (product && product.Imagen) {
      handleFetchImages(product.Imagen);
    }
  }, [product]);

  useEffect(() => {
    if (checked) {
      handleAddProductCard();
      setCant(1);
    } else {
      removeProductFromCart(product.CodArticulo);
      setCant(0);
    }
  }, [checked]);

  useEffect(() => {
    // Busca el producto en arrayProductShop y actualiza cant si existe
    const productInShop = arrayProductShop.find(
      (item) => item.CodArticulo === product.CodArticulo
    );

    if (productInShop) {
      setCant(productInShop.quantity || 1);
    } else {
      setCant(0);
    }
  }, [arrayProductShop, product.CodArticulo]);

  useEffect(() => {
    if (
      arrayProductShop.some((item) => item.CodArticulo === product.CodArticulo)
    ) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [arrayProductShop, product.CodArticulo]);

  useEffect(() => {
    if (cant > 0) {
      setChecked(true);
      setArrayProductShop((prevArray) => {
        return prevArray.map((item) =>
          item.CodArticulo === product.CodArticulo
            ? { ...item, quantity: cant }
            : item
        );
      });
    }
    if (cant < 1) setChecked(false);
  }, [cant]);
  useEffect(() => {
    setfamily(product?.SupFlia?.SupFlia);
    console.log("family:", family);
  }, [product]);

  return (
    <Card
      // onClick={handleCardClick}
      className="---ProductCard.js---"
      sx={{
        width: 320,
        maxWidth: 320,
        height: 350,
        maxHeight: 350,
        boxShadow: "lg",
        margin: 1,
        cursor: "pointer",
        borderRadius: "8px",
        overflow: "hidden",
        backgroundColor: "white",
        "&:hover": {
          boxShadow: "xl",
          transform: "scale(1.02)",
          transition: "all 0.2s ease-in-out",
        },
      }}
    >
      <CardOverflow>
        <AspectRatio
          objectFit="contain"
          onClick={handleCardClick}
          sx={{ minWidth: 200 }}
        >
          {!imageLoad ? (
            <div>
              <ImageIcon sx={{ fontSize: "3rem", opacity: 0.2 }} />
            </div>
          ) : (
            <img
              style={{ backgroundColor: "white" }}
              src={imageLoad}
              loading="lazy"
              alt={product?.Descripcion || "Sin imagen"}
            />
          )}
        </AspectRatio>
      </CardOverflow>
      <CardContent>
        <Typography level="title-md">{product.descripcion}</Typography>
        {CodCliente !== "" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 3,
            }}
          >
            <Typography level="body-sm" sx={{ mt: 1, fontWeight: "xl" }}>
              {currency} {product.precio}
            </Typography>
            <Typography level="body-sm" sx={{ mt: 1, fontWeight: "xl" }}>
              Stock: {product?.stock || 0}
            </Typography>
            <Typography level="body-sm" sx={{ mt: 1, fontWeight: "xl" }}>
              Cod: {product?.CodArticulo || 0}
            </Typography>
          </Box>
        )}
      </CardContent>
      {CodCliente !== "" && (
        <CardOverflow>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              accentColor: "#d6381b",
            }}
          >
            <Button
              variant="solid"
              size="lg"
              onClick={handleCheckboxClick}
              disabled={product.stock <= 0}
              sx={{
                backgroundColor: product.stock > 0 ? "#d6381b" : "#ccc",
                color: "#fff",
                width: "100%",
                maxWidth: "300px",
                height: "70px",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": {
                  backgroundColor: product.stock > 0 ? "#C61818" : "#ccc",
                },
              }}
            >
              Agregar al Carrito
              <WhiteCheckbox
                {...label}
                icon={
                  <ShoppingCartOutlinedIcon
                    sx={{ fontSize: 25, color: "white" }}
                  />
                } // Carrito blanco
                checkedIcon={
                  <ShoppingCartIcon sx={{ fontSize: 25, color: "white" }} />
                } // Carrito negro
                sx={{ left: "10%" }}
                checked={checked}
                onChange={handleCheckboxClick}
                disabled={product.stock <= 0}
              />
            </Button>
            <TextField
              type="number"
              value={cant || 0}
              onChange={(e) => {
                setCant(+e.target.value);
              }}
              disabled={checked ? false : true}
              sx={{
                maxHeight: "50px",
                backgroundColor: "white",
                width: "80px",
                marginLeft: "5%",
              }}
              inputProps={{
                min: 0,
                max: product.stock,
                step: 1,
                sx: {
                  paddingRight: "6px",
                  paddingLeft: "10px",
                },
              }}
            />
          </Box>
        </CardOverflow>
      )}

      {/* {CodCliente == "" ? (
        family == 17 && (
          <Typography level="title-lg" sx={{ mt: 1, fontWeight: "xl" }}>
            {currency} {product.precio}
          </Typography>
        )
      ) : (
        <Typography>Debe iniciar sesión para ver precios</Typography>
      )} */}

      {CodCliente === "" &&
        (family == 17 ? (
          <Typography level="title-lg" sx={{ mt: 1, fontWeight: "xl" }}>
            {currency} {product.precio}
          </Typography>
        ) : (
          <Typography>Debe iniciar sesión para ver precios</Typography>
        ))}

      <EditProductModal
        open={open}
        handleClose={handleCloseModalProduct}
        product={product}
        imageLoad={imageLoad}
      />
    </Card>
  );
}
