import { createContext, useState, useEffect,useRef } from 'react';

export const Context = createContext();

const Provider = ({ children }) => {
	const testContext = 'Context Exitoso';
	const [categories, setCategories] = useState([]);
	const [subCategories, setSubCategories] = useState([]);
	const [groups, setGroups] = useState([]);
	const [articles, setArticles] = useState([]);
	const [sectionSelected, setSectionSelected] = useState('Carrousel');
	const [arrayProductShop, setArrayProductShop] = useState([]);
	const [primaryColor, setPrimaryColor] = useState('E21B1B');
	// const [search, setSearch] = useState('');
	const search = useRef('');
	const [email, setEmail] = useState('');
	const [userName, setUserName] = useState('');
	const [userLogged, setUserLogged] = useState(false);
	const [rol, setRol] = useState('');
	const [tokenJar, setTokenJar] = useState('');
	const [CodCliente, setCodCliente] = useState('');
	const [FirstLogin, setFirstLogin] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [searchChecked, setSearchChecked] = useState(false);
	const [showMainCarrousel, setShowMainCarrousel] = useState(true);
	
	// Función para leer de sessionStorage
	const getSessionStorageItem = (key) => {
		const item = sessionStorage.getItem(key);
		return item !== 'null' && item !== 'undefined' ? item : null;
	};

	// Función para inicializar el estado desde sessionStorage
	const initializeStateFromSessionStorage = () => {
		const storedUserLogged = getSessionStorageItem('UserLogged');
		const storedCodCliente = getSessionStorageItem('CodCliente');
		const storedUserName = getSessionStorageItem('UserName');
		const storedUserRol = getSessionStorageItem('UserRol');

		if (storedUserLogged === 'true') {
			setUserLogged(true);
			if (storedCodCliente) {
				const parsedValue = parseInt(storedCodCliente);
				if (!isNaN(parsedValue)) {
					setCodCliente(parsedValue);
				}
			}
		}
		if (storedUserRol) {
			setRol(storedUserRol);
		}
		if (storedUserName) {
			setUserName(storedUserName);
		}
	};

	// Inicializar el estado desde sessionStorage al montar el componente
	useEffect(() => {
		initializeStateFromSessionStorage();
	}, []);

	// Guardar cambios en sessionStorage
	useEffect(() => {
		sessionStorage.setItem('UserLogged', userLogged);
		if (CodCliente && CodCliente !== '-') {
			sessionStorage.setItem('CodCliente', CodCliente);
		}
		sessionStorage.setItem('UserRol', rol);
		sessionStorage.setItem('UserName', userName);
	}, [userLogged, CodCliente, rol, userName]);

	useEffect(() => {
		console.log('CODIGO CLIENTE: ', CodCliente);
	}, [CodCliente]);

	const value = {
		testContext,
		categories,
		setCategories,
		subCategories,
		setSubCategories,
		groups,
		setGroups,
		articles,
		setArticles,
		sectionSelected,
		setSectionSelected,
		primaryColor,
		setPrimaryColor,
		arrayProductShop,
		setArrayProductShop,
		search,
		// setSearch,
		email,
		setEmail,
		userName,
		setUserName,
		userLogged,
		setUserLogged,
		rol,
		setRol,
		tokenJar,
		setTokenJar,
		showMainCarrousel,
		setShowMainCarrousel,
		CodCliente,
		setCodCliente,
		FirstLogin,
		setFirstLogin,
		refresh, 
		setRefresh,
		searchChecked,
		setSearchChecked,
	};

	return <Context.Provider value={value}>{children}</Context.Provider>;
};

export { Provider };
