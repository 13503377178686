import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import {
	Grid,
	TextField,
	Stack,
	IconButton,
	Menu,
	MenuItem,
	Button,
	Badge,
	CircularProgress,
	Box,
	Typography,
	FormControl,
	InputAdornment,
	Checkbox,
} from '@mui/material';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from './Assets/logo.png';
import perfilIcon from './Assets/perfil.png';
import carritoIcon from './Assets/carrito-de-compras.png';
import RegisterModal from '../components/modalRegister';
import AboutUsModal from '../components/AboutUs';
import LoginModal from '../components/Login/modalLogin';
import PreOrderModal from '../components/utils/PreOrderModal';
import useMainContext from '../components/Context/useMainContext';
import {
	getArticlesByText,
	getArticlesWithStock,
	getToken,
} from '../Macrosoft_API/index';
import MenuAdminContainer from '../components/AdminPanel/MenuAdminContainer';
import '../components/utils/assets/headerPrincipal.css';
import ChangePassword from '../components/Login/ChangePassword';
import SearchIcon from '@mui/icons-material/Search';

const theme = createTheme({
	palette: {
		primary: {
			main: '#E21B1B', // Define tu color principal aquí
		},
	},
});
export default function HeaderPrincipal() {
	const [anchorEl, setAnchorEl] = useState(null);
	const [isInitialized, setIsInitialized] = useState(false); // Nuevo estado para inicialización
	const menuButtonRef = useRef(null); // Referencia para el botón del menú

	useEffect(() => {
		setIsInitialized(true); // Marca que la inicialización está completa
	}, []);

	const handleMenuClick = () => {
		setAnchorEl(menuButtonRef.current);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const [openModalRegister, setOpenModalRegister] = useState(false);
	const [openModalLogin, setOpenModalLogin] = useState(false);
	const [openModalPreOrder, setOpenModalPreOrder] = useState(false);
	const [aboutUs, setAboutUs] = useState(false);
	const [openModalChangePassword, setopenModalChangePassword] = useState(false);

	const [loading, setLoading] = useState(false);

	/* context */
	const {
		CodCliente,
		userName,
		setShowMainCarrousel,
		setArticles,
		userLogged,
		setUserLogged,
		setUserName,
		arrayProductShop,
		setRol,
		rol,
		search,
		// setSearch,
		setSectionSelected,
		setCodCliente,
		searchChecked,
		setSearchChecked,
	} = useMainContext();
	// const handleModifySearch = (e)=>{
	// 	search.current=search.current + e.target.value
	// 	console.log('se ejecuta: ',search.current)
	// }
	const handleSearch = async () => {
		setLoading(true);
		setSectionSelected('ShopNavigator');
		const token = await getToken();
		try {
			if (!searchChecked) {
				//TEXT
				const response = await getArticlesByText({
					search: search?.current?.value,
					CodCliente,
					family: '-',
					subFamily: '-',
					group: '-',
					CodStock: '-',
				});
				setArticles(response);
			} else {
				//CHECK
				const response = await getArticlesWithStock(
					token,
					CodCliente || 101,
					search
				);
				setArticles(response.data);
			}

			setShowMainCarrousel(false);
		} catch (error) {
			console.log('Hubo un error', error);
		} finally {
			setLoading(false);
		}
	};

	const handleLogOut = () => {
		setRol(null);
		setUserLogged(false);
		setUserName('');
		setCodCliente('');
		LogoClick();
		sessionStorage.setItem('UserLogged', false);
		sessionStorage.clear();
	};

	function LogoClick() {
		setSectionSelected('Carrousel');
		// setSearch("");
	}

	// Renderiza un loader mientras se inicializan los estados
	if (!isInitialized) {
		return (
			<Box
				sx={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					bgcolor: 'rgba(255, 255, 255, 1)',
					zIndex: 1300,
				}}
			>
				<CircularProgress color='primary' />
			</Box>
		);
	}

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleSearch();
		}
	};

	const handleIconClick = () => {
		handleSearch();
	};

	const handleAboutUs = () => {
		setAboutUs(true);
	};

	return (
		<ThemeProvider theme={theme}>
			<Grid
				className='--header-principal--'
				container
				sx={{
					display: 'flex',
					justifyContent: 'space-around',
					marginBottom: {
						xs: '1rem',
					},
					// borderBottom: 'rgba(0, 0, 0, 0.87)',
					// background: 'rgb(230, 118, 118)',
				}}
				id='header_target'
			>
				{/* Logo */}
				<Grid
					item
					xs={7}
					sm={2}
					md={2}
					lg={1}
					// width={'20%'}
					sx={
						{
							// background: 'rgb(113, 27, 226)',
						}
					}
				>
					<img
						style={{
							cursor: 'pointer',
						}}
						onClick={() => {
							LogoClick();
						}}
						width={'70%'}
						src={logo}
						alt={'Erebil'}
						loading='lazy'
					/>
				</Grid>

				<Grid
					className='--search-div--'
					item
					xs={10}
					sm={10}
					md={9}
					lg={7}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						// background: 'rgb(34, 226, 27)',
					}}
				>
					{userLogged && rol === 'Administrador' ? (
						<FormControl sx={{ display: 'flex', width: '80%' }}>
							<TextField
								fullWidth
								label='Buscador..'
								onKeyDown={(e) => handleKeyPress(e)}
								// onChange={(e) => {
								// 	handleSearchCallBack(e.target.value);
								//   }}
								inputRef={search}
								InputProps={{
									endAdornment: (
										<InputAdornment
											position='end'
											sx={{ display: 'flex', alignItems: 'center' }}
										>
											<Typography
												variant='caption'
												sx={{ mr: 1 }}
											>
												Buscar con códigode articulo
											</Typography>
											<Checkbox
												checked={searchChecked}
												onChange={() => {
													setSearchChecked(!searchChecked);
												}}
												size='small'
											/>
										</InputAdornment>
									),
								}}
							/>
						</FormControl>
					) : (
						<TextField
							sx={{ display: 'flex', width: '80%' }}
							label='Buscador..'
							inputRef={search}
							onKeyDown={(e) => handleKeyPress(e)}
							//   onChange={(e) => {
							// 	handleSearchCallBack(e.target.value);
							//   }}
							InputProps={{
								endAdornment: (
									<InputAdornment
										position='start'
										sx={{ display: 'flex', alignItems: 'center' }}
									>
										<SearchIcon
											sx={{ cursor: 'pointer' }}
											onClick={handleIconClick}
										/>
									</InputAdornment>
								),
							}}
						/>
					)}
				</Grid>

				<Grid
					className='--botones-izq--'
					item
					xs={12}
					sm={12}
					md={12}
					lg={4}
					sx={{
						display: { xs: 'none', sm: 'flex', lg: 'flex' },
						justifyContent: 'space-around',
						// background: ' #1B1EE2',
						padding: '0 1%',
					}}
				>
					<Stack
						direction='row'
						spacing={2}
						alignItems='center'
						sx={{
							display: 'flex',
							// background: 'rgb(226, 196, 27)',
							justifyContent: 'space-around',
							width: '100%',
							padding: '0 1%',
						}}
					>
						<Grid
							item
							xs={12}
							sm={3}
							md={3}
							lg={1}
							sx={{
								paddingRight: { lg: '20%' },
								// background: 'rgb(226, 116, 27)',
							}}
						>
							<Button
								id='about_us_link'
								onClick={handleAboutUs}
								sx={{ fontSize: '.2rem', background: 'red' }}
							>
								¿Quiénes Somos?
							</Button>
						</Grid>
						<Badge
							badgeContent={arrayProductShop?.length}
							color='error'
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
						>
							<IconButton
								onClick={() => {
									if (arrayProductShop.length > 0) {
										setOpenModalPreOrder(true);
									}
								}}
							>
								<img
									src={carritoIcon}
									alt='Carrito'
									style={{ width: '3rem', height: '3rem' }}
								/>
							</IconButton>
						</Badge>

						<div>
							<IconButton
								ref={menuButtonRef}
								sx={{
									width: '4rem', // Ajusta el tamaño total del botón
									height: '4rem',
								}}
								onClick={handleMenuClick}
							>
								<img
									src={perfilIcon}
									alt='Perfil'
									style={{
										width: '3rem',
										height: '3rem',
									}}
								/>
							</IconButton>
							<Menu
								anchorEl={anchorEl}
								open={Boolean(anchorEl)}
								onClose={handleMenuClose}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'center',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'center',
								}}
							>
								{!userLogged ? (
									<MenuItem
										onClick={() => {
											setOpenModalLogin(true);
											handleMenuClose();
										}}
									>
										Iniciar Sesión
									</MenuItem>
								) : (
									[
										<MenuItem key='username'>{userName}</MenuItem>,
										<MenuItem
											key='change-password'
											onClick={() => {
												setopenModalChangePassword(true);
												handleMenuClose();
											}}
										>
											Cambiar mi Contraseña
										</MenuItem>,
										<MenuItem
											key='logout'
											onClick={() => {
												handleLogOut();
												handleMenuClose();
											}}
										>
											Cerrar Sesión
										</MenuItem>,
									]
								)}
							</Menu>
						</div>

						{!userLogged && (
							<Button
								variant='contained'
								onClick={() => setOpenModalRegister(true)}
								sx={{ maxWidth: '300px', minWidth: '130px' }} // Clase CSS para animación
							>
								REGISTRARME
							</Button>
						)}

						{userLogged && rol === 'Administrador' && (
							<Grid
								sx={{
									display: 'flex',
									alignContent: 'center',
									alignItems: 'center',
								}}
							>
								<MenuAdminContainer />
								<Typography>Panel Administrador</Typography>
							</Grid>
						)}
					</Stack>
				</Grid>
			</Grid>
			<RegisterModal
				openModalRegister={openModalRegister}
				setopenModalRegister={setOpenModalRegister}
			/>
			<LoginModal
				openModalLogin={openModalLogin}
				setopenModalLogin={setOpenModalLogin}
			/>
			<ChangePassword
				openModalChangePassword={openModalChangePassword}
				setopenModalChangePassword={setopenModalChangePassword}
				actualpass='Contraseña Actual'
			></ChangePassword>
			<PreOrderModal
				open={openModalPreOrder}
				handleClose={() => {
					setOpenModalPreOrder(false);
				}}
			/>
			<AboutUsModal
				aboutUs={aboutUs}
				setAboutUs={setAboutUs}
			></AboutUsModal>
			{loading && (
				<Box
					sx={{
						position: 'fixed',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						bgcolor: 'rgba(0, 0, 0, 0.5)',
						zIndex: 1300,
					}}
				>
					<CircularProgress color='primary' />
				</Box>
			)}
		</ThemeProvider>
	);
}
