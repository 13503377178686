import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CarrouselSeccionAliados from './CarrouselSeccionAliados';
import useMediaQuery from '@mui/material/useMediaQuery';
import foto1 from '../../erebil/Assets/Carrousel_Aliados/1.jpg';
import foto2 from '../../erebil/Assets/Carrousel_Aliados/2.jpg';
import foto3 from '../../erebil/Assets/Carrousel_Aliados/3.png';
import foto4 from '../../erebil/Assets/Carrousel_Aliados/4.png';
import foto5 from '../../erebil/Assets/Carrousel_Aliados/5.png';
import foto7 from '../../erebil/Assets/Carrousel_Aliados_New/promolux.png';
import foto8 from '../../erebil/Assets/Carrousel_Aliados_New/pelco.png';
import foto9 from '../../erebil/Assets/Carrousel_Aliados_New/tuya.png';
import foto10 from '../../erebil/Assets/Carrousel_Aliados_New/itc.png';
import foto11 from '../../erebil/Assets/Carrousel_Aliados_New/gsn.png';
import foto12 from '../../erebil/Assets/Carrousel_Aliados/12.jpg';
import { Typography } from '@mui/material';

export default function SectionAliados() {
	const isSmallScreen = useMediaQuery('(max-width:600px)');
	const isMediumScreen = useMediaQuery('(max-width:960px)');
	const isLargeScreen = useMediaQuery('(min-width:961px)');
	const [config, setConfig] = useState({
		itemsPerStep: 6,
		size: { width: '50%', height: 110 },
	});
	const images = [
		{
			label: 'Checkpoint',
			imgPath: foto1,
			link: 'https://www.checkpoint.com/es/',
		},
		{
			label: 'Dahua',
			imgPath: foto2,
			link: 'https://www.dahuasecurity.com/es',
		},
		{
			label: 'Avance',
			imgPath: foto3,
			link: 'https://www.avancesrl.com.uy/',
		},
		{
			label: 'Kronnix',
			imgPath: foto4,
			link: 'http://kronnix.com/',
		},
		{
			label: 'Alpha',
			imgPath: foto5,
			link: 'https://www.alphaworld.com/',
		},
		{
			label: 'Promolux',
			imgPath: foto7,
			link: 'https://www.promolux.com/',
		},
		{
			label: 'Pelco',
			imgPath: foto8,
			link: 'https://www.pelco.com/',
		},
		{
			label: 'Tuya',
			imgPath: foto9,
			link: 'https://www.tuya.com/',
		},
		{
			label: 'ITC',
			imgPath: foto10,
			link: 'https://itc.com.uy/',
		},
		{
			label: 'GSN',
			imgPath: foto11,
			link: 'https://www.gsncompany.com/',
		},
		{
			label: 'foto12',
			imgPath: foto12,
			link: 'https://www.dahuasecurity.com/es',
		},
	];
	useEffect(() => {
		// Ajustar los valores dinámicamente según el tamaño de la pantalla
		if (isSmallScreen) {
			setConfig({ itemsPerStep: 2, size: { width: '100%', height: 80 } }); // Configuración para pantallas pequeñas
		} else if (isMediumScreen) {
			setConfig({ itemsPerStep: 4, size: { width: '75%', height: 100 } }); // Configuración para pantallas medianas
		} else if (isLargeScreen) {
			setConfig({ itemsPerStep: 6, size: { width: '50%', height: 110 } }); // Configuración para pantallas grandes
		}
	}, [isSmallScreen, isMediumScreen, isLargeScreen]);
	return (
		<Box sx={{ flexGrow: 1, paddingY: 4 }}>
			<Grid
				className='---NUESTROS-ALIADOS---'
				container
				direction='column'
				justifyContent='center'
				alignItems='center'
				sx={{ textAlign: 'center' }} // Centramos tanto vertical como horizontalmente
			>
				<Grid
					item
					xs={12}
				>
					<Typography variant='h5'>NUESTROS ALIADOS</Typography>
				</Grid>
				<Grid item>
					{/* Carrusel centrado */}
					<CarrouselSeccionAliados
						images={images}
						size={config.size}
						itemsPerStep={config.itemsPerStep}
					/>
				</Grid>
			</Grid>
		</Box>
	);
}
