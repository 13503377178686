import * as React from 'react';
import { useState } from 'react';
import { Grid } from '@mui/material';
import { MenuLat } from './MenuLat';
import { ShopMain } from './ShopMain';
export const ShopNavigator = () => {
	return (
		<>
			<Grid
				className='---ShopNavigator.js---'
				container
				sx={{
					height: '100%',
					width: '100%',
					border: '1px solid #cdcfcc',
					borderBottom: 'none',
					marginTop: {
						xs: '',
						sm: '1rem',
						md: '1rem',
					},
					flexWrap: 'nowrap',
					boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1)',
				}}
			>
				<Grid
					className='UUUUUUUUUUU'
					item
				>
					<MenuLat />
				</Grid>
				<Grid
					sx={{ width: '100%', paddingTop: '1rem' }}
					item
				>
					<ShopMain />
				</Grid>
			</Grid>
		</>
	);
};
