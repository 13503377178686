import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function CarrouselSeccionAliados({ images, size, itemsPerStep = 1 }) {
	const theme = useTheme();
	const [activeStep, setActiveStep] = React.useState(0);

	// Function to group images based on the itemsPerStep
	const groupImages = (arr, groupSize) => {
		const groups = [];
		for (let i = 0; i < arr.length; i += groupSize) {
			groups.push(arr.slice(i, i + groupSize));
		}
		return groups;
	};

	const groupedImages = groupImages(images, itemsPerStep);
	const maxSteps = groupedImages.length;

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStepChange = (step) => {
		setActiveStep(step);
	};
	const handleRef = (link) => {
		console.log('link ', link);
		window.open(link, '_blank', 'noopener,noreferrer');
	};
	return (
		<Box sx={{ width: '100%', flexGrow: 1 }}>
			<Paper
				square
				elevation={0}
				sx={{
					display: 'flex',
					alignItems: 'center',
					height: 70,
					pl: 2,
					bgcolor: 'background.default',
				}}
			>
				{/* Optional title or description can be placed here */}
			</Paper>
			<AutoPlaySwipeableViews
				axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
				index={activeStep}
				onChangeIndex={handleStepChange}
				enableMouseEvents
			>
				{groupedImages.map((group, index) => (
					<div key={index}>
						{Math.abs(activeStep - index) <= 2 ? (
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									overflow: 'hidden',
									width: '100%',
								}}
							>
								{group.map((step, idx) => (
									//<a href={images?.link} style={{ display: 'inline-block' }} target="_blank" rel="noopener noreferrer">
									<Box
										key={idx}
										component='img'
										sx={{
											height: size?.height || '100%',
											width: size?.width || 200,
											margin: '0 10px',
											objectFit: 'cover',
											cursor: 'pointer',
										}}
										src={step.imgPath}
										alt={step.label}
										onClick={() => {
											handleRef(step.link);
										}}
									/>
									// </a>
								))}
							</Box>
						) : null}
					</div>
				))}
			</AutoPlaySwipeableViews>
			<MobileStepper
				steps={maxSteps}
				position='static'
				activeStep={activeStep}
				nextButton={
					<Button
						size='small'
						onClick={handleNext}
						disabled={activeStep === maxSteps - 1}
					>
						{theme.direction === 'rtl' ? (
							<KeyboardArrowLeft />
						) : (
							<KeyboardArrowRight />
						)}
					</Button>
				}
				backButton={
					<Button
						size='small'
						onClick={handleBack}
						disabled={activeStep === 0}
					>
						{theme.direction === 'rtl' ? (
							<KeyboardArrowRight />
						) : (
							<KeyboardArrowLeft />
						)}
					</Button>
				}
			/>
		</Box>
	);
}

export default CarrouselSeccionAliados;
