import { useState, useEffect } from 'react';
import useMainContext from './Context/useMainContext';
import {
	Box,
	Button,
	Grid,
	FormControl,
	InputLabel,
	TextField,
	Select,
	MenuItem,
	Modal,
	Alert,
	Typography,
} from '@mui/material';
import '../erebil/Assets/stylesFormRegister.css';
import {
	fetchCreateUser,
	fetchRequestRegister,
	fetchUpdateUser,
} from './controller/userController';
import ModalAlert from './utils/modalAlert';
import { useForm } from 'react-hook-form';
import Backdrop from '@mui/material/Backdrop';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: {
		xs: '90%',
		sm: '40%',
		md: '40%',
	},
	height: {
		xs: '90%',
		sm: '45%',
		md: '45%',
		lg: '95%',
	},
	bgcolor: 'background.paper',
	boxShadow: 24,
	borderRadius: '10px',
	overflow: 'scroll',
};

export default function RegisterModal({
	openModalRegister,
	setopenModalRegister,
	user,
	setUserSelected
}) {
	const { rol, refresh, setRefresh } = useMainContext();

	const [open, setOpen] = useState(false);
	const [userType, setUserType] = useState(2);
	const [id, setId] = useState(false);
	const [password, setPassword] = useState('');
	const [email, setEmail] = useState('');
	const [Nombre, setNombre] = useState('');
	const [NombreFantasia, setnombreFantasia] = useState('');
	const [DireccionParticular, setdireccionParticular] = useState('');
	const [DireccionTrabajo, setdireccionTrabajo] = useState('');
	const [TelefonoParticular, settelefonoParticular] = useState('');
	const [TelefonoTrabajo, settelefonoTrabajo] = useState('');
	const [RUT, setRUT] = useState('');
	const [Cedula, setCedula] = useState('');
	const [Celular, setCelular] = useState('');
	const [incluyeIVA, setIncluyeIVA] = useState(null);
	const [moneda, setMoneda] = useState(null);
	const [descuento, setDescuento] = useState(null);
	const [CodCliente, setCodCliente] = useState('');
	const [estado, setEstado] = useState(false);
	const [modalAlert, setModalAlert] = useState(false);
	const [text, setText] = useState('');
	const handleChange = (event) => {
		setUserType(event.target.value);
	};
	const [handleAlert, setHandleAlert] = useState({
		display: false,
		text: '',
		metodo: '',
	});
	const handleRegister = async () => {
		const data = {
			email,
			Nombre,
			NombreFantasia,
			DireccionParticular,
			DireccionTrabajo,
			TelefonoParticular,
			TelefonoTrabajo,
			RUT,
			Cedula,
			Celular,
			CodCliente,
			userType,
			estado,
			id_rol: userType,
			incluyeIVA,
			moneda,
			descuento
		};

		console.log('data register:', data);

		try {
			const response =
				rol === 'Administrador'
					? await fetchCreateUser(data)
					: await fetchRequestRegister(data);
			if (response.status === 201) {
				setHandleAlert({
					display: true,
					metodo: 'success',
					text: response.data.message,
				});
			}
			setRefresh(true);
			return;
		} catch (error) {
			console.log('hubo un error: ', error);
			setHandleAlert({
				display: true,
				metodo: 'error',
				text:
					error?.data?.message || 'Hubo un error al registrar el usuario',
			});
		}
	};

	const handleClose = () => {
		handleCleanInput();
		setopenModalRegister(false);
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const handleCleanInput = () => {
		setnombreFantasia('');
		setPassword('');
		setEmail('');
		setNombre('');
		setdireccionParticular('');
		setdireccionTrabajo('');
		settelefonoParticular('');
		settelefonoTrabajo('');
		setRUT('');
		setCedula('');
		setCelular('');
		setCodCliente('');
		setHandleAlert({
			display: false,
			text: '',
			metodo: '',
		});

		if (user) {
			setUserSelected(false);
		}
	};

	const handleUserUpdate = async () => {
		const data = {
			email,
			Nombre,
			NombreFantasia,
			DireccionParticular,
			DireccionTrabajo,
			TelefonoParticular,
			TelefonoTrabajo,
			RUT,
			Cedula,
			Celular,
			CodCliente,
			id,
			estado,
			id_rol: userType,
		};

		console.log('usuario: ', data);

		try {
			const response = await fetchUpdateUser(data);
			if (response.status === 200) {
				setHandleAlert({
					display: true,
					metodo: 'success',
					text: response.data.message,
				});
			}
			setRefresh(true);
			return;
		} catch (error) {
			console.log('hubo un error al modificar: ', error);
		}
	};

	// const handleOpenModalAlert = () => {
	// 	setText('Se ha enviado un email con la solicitud');
	// 	setModalAlert(true);
	// };

	useEffect(() => {
		if (user) {
			console.log('usuario recibido: ', user);
			setId(user.id);
			setNombre(user.Nombre);
			setnombreFantasia(user.nombreFantasia);
			setEmail(user.Email);
			setdireccionParticular(user.DireccionParticular);
			setdireccionTrabajo(user.DireccionTrabajo);
			settelefonoParticular(user.TelefonoParticular);
			settelefonoTrabajo(user.TelefonoTrabajo);
			setRUT(user.RUT);
			setCedula(user.Cedula);
			setCelular(user.Celular);
			setCodCliente(user.CodCliente);
			setIncluyeIVA(user.incluyeIVA);
			setMoneda(user.Moneda);
			setDescuento(user.setDescuento);
			setUserType(user.id_rol);
			setEstado(user.estado);
		}
	}, [user]);

	return (
		<div>
			<Modal
				open={openModalRegister}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				slots={{
					backdrop: (props) => (
						<Backdrop
							{...props}
							onClick={(e) => e.stopPropagation()}
						/>
					),
				}}
			>
				<Box sx={style}>
					<Grid container>
						<Grid className='headerFormRegister'>
							<h2 className='tituloFormRegister'>
								{user ? 'EDICION DE USUARIO' : 'FORMULARIO DE REGISTRO'}
							</h2>
						</Grid>

						<Grid className='containerFormRegister'>
							<Grid className='containerInputForm'>
								<TextField
									id='nombre'
									label='Nombre'
									value={Nombre}
									fullWidth
									margin='normal'
									{...register('nombre', {
										required: 'El nombre es obligatorio',
										pattern: {
											value: /^[a-zA-Z\s]+$/,
											message: 'Solo se permiten letras y espacios',
										},
									})}
									error={!!errors.Nombre}
									helperText={errors.Nombre?.message}
									onChange={(e) => setNombre(e.target.value)}
								/>
								<TextField
									id='nombre fantasia'
									label='Nombre Fantasía'
									value={NombreFantasia}
									fullWidth
									margin='normal'
									onChange={(e) => setnombreFantasia(e.target.value)}
								/>
								<TextField
									id='email'
									label='Email'
									value={email}
									fullWidth
									margin='normal'
									{...register('email', {
										required: 'El email es obligatorio',
										pattern: {
											value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
											message: 'Email no válido',
										},
										onChange: (e) => {
											setEmail(e.target.value);
										},
									})}
									error={!!errors.email}
									helperText={errors.email?.message}
								/>

								<TextField
									id='direccion_particular'
									label='Dirección Particular'
									value={DireccionParticular}
									fullWidth
									margin='normal'
									onChange={(e) => setdireccionParticular(e.target.value)}
								/>
								<TextField
									id='direccion_trabajo'
									label='Dirección Trabajo'
									value={DireccionTrabajo}
									fullWidth
									margin='normal'
									onChange={(e) => setdireccionTrabajo(e.target.value)}
								/>
								<TextField
									id='telefono_particular'
									label='Teléfono Particular'
									value={TelefonoParticular}
									fullWidth
									margin='normal'
									onChange={(e) => settelefonoParticular(e.target.value)}
								/>
								<TextField
									id='telefono_trabajo'
									label='Teléfono Trabajo'
									value={TelefonoTrabajo}
									fullWidth
									margin='normal'
									onChange={(e) => settelefonoTrabajo(e.target.value)}
								/>
								<TextField
									id='RUT'
									label='RUT'
									value={RUT}
									fullWidth
									margin='normal'
									onChange={(e) => setRUT(e.target.value)}
								/>
								<TextField
									id='Cedula'
									label='Cédula'
									value={Cedula}
									fullWidth
									margin='normal'
									onChange={(e) => setCedula(e.target.value)}
								/>
								<TextField
									id='Celular'
									label='Celular'
									value={Celular}
									fullWidth
									margin='normal'
									{...register('Celular', {
										required: 'El Celular es obligatorio',
										min: {
											value: 1,
											message: 'No puede enviar este campo vacío',
										},
									})}
									error={!!errors.Celular}
									helperText={errors.Celular?.message}
									onChange={(e) => setCelular(e.target.value)}
								/>
								{rol === 'Administrador' && (
									<>
										<TextField
											id='CodCliente'
											label='CodCliente'
											value={CodCliente}
											fullWidth
											margin='normal'
											onChange={(e) => setCodCliente(e.target.value)}
										/>
										<TextField
											id='IncluyeIVA'
											label='IVA'
											value={incluyeIVA}
											fullWidth
											margin='normal'
											onChange={(e) => setIncluyeIVA(e.target.value)}
										/>
										<TextField
											id='Moneda'
											label='Moneda'
											value={moneda}
											fullWidth
											margin='normal'
											onChange={(e) => setMoneda(e.target.value)}
										/>
										<TextField
											id='Descuento'
											label='Descuento'
											value={descuento}
											fullWidth
											margin='normal'
											onChange={(e) => setDescuento(e.target.value)}
										/>
										<FormControl
											fullWidth
											sx={{ marginTop: 2 }}
										>
											<InputLabel id='demo-simple-select-label'>
												Estado del usuario
											</InputLabel>
											<Select
												labelId='demo-simple-select-label'
												id='demo-simple-select'
												value={estado ?? false}
												label='Estado del usuario'
												onChange={(e) => {
													setEstado(e.target.value);
												}}
											>
												<MenuItem
													sx={{ color: 'green' }}
													value={true}
												>
													Habilitado
												</MenuItem>
												<MenuItem
													sx={{ color: 'red' }}
													value={false}
												>
													Deshabilitado
												</MenuItem>
											</Select>
										</FormControl>
									</>
								)}

								<FormControl
									fullWidth
									sx={{ marginTop: 2 }}
								>
									<InputLabel id='demo-simple-select-label'>
										Seleccione el tipo de usuario
									</InputLabel>
									<Select
										labelId='demo-simple-select-label'
										id='demo-simple-select'
										value={userType ?? 2}
										label='Seleccione el tipo de usuario'
										onChange={handleChange}
									>
										<MenuItem value={'Cliente'}>Cliente</MenuItem>
										<MenuItem value={'Proveedor'}>Proveedor</MenuItem>
										{rol === 'Administrador' && (
											<MenuItem value={'Administrador'}>Administrador</MenuItem>
										)}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
						<Grid
							sx={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								marginTop: '3%',
							}}
						>
							{handleAlert?.display && (
								<Alert
									severity={handleAlert?.metodo}
									sx={{
										width: '100%',
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									<Typography sx={{ textAlign: 'center' }}>
										{handleAlert?.text}
									</Typography>
								</Alert>
							)}
						</Grid>
						<Grid
							container
							className='--div-botones--'
							sx={{
								display: 'flex',
								justifyContent: 'space-around',
								padding: '1rem 0',
							}}
						>
							<Grid item>
								{user ? (
									<Button
										onClick={handleUserUpdate}
										variant='outlined'
										sx={{
											background: 'white',
											color: '#E21B1B',
											border: '1px solid red',
											minWidth: '130px',
											'&:hover': {
												background: '#B71515',
											},
										}}
									>
										Modificar
									</Button>
								) : (
									<Button
										onClick={handleSubmit(handleRegister)}
										variant='outlined'
										sx={{
											background: 'white',
											color: '#E21B1B',
											border: '1px solid red',
											minWidth: '130px',
											'&:hover': {
												background: '#B71515',
											},
										}}
									>
										Registrarme
									</Button>
								)}
							</Grid>
							<Grid item>
								<Button
									onClick={handleClose}
									variant='outlined'
									sx={{
										background: 'white',
										color: '#E21B1B',
										border: '1px solid red',
										minWidth: '130px',
										'&:hover': {
											background: '#B71515',
										},
									}}
								>
									Cerrar
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Modal>
			<ModalAlert
				open={modalAlert}
				handleClose={() => {
					setModalAlert(false);
					handleCleanInput();
					handleClose();
				}}
				text={text}
			/>
		</div>
	);
}
