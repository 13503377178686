import React from 'react';
import { useState, useEffect } from 'react';
import Header from './headerContainer';
import Carrousel from './carrousel';
import SectionAliados from '../components/Sections/SectionAliados';
import {
	Grid,
	Button,
	CircularProgress,
	Box,
	Badge,
	IconButton,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { ProductsCatalog } from '../components/ProductsCatalog';
import Typography from '@mui/joy/Typography';
import { Section } from '../components/Section';
import ContactFooter from '../components/ContactFooter';
import { ShopNavigator } from '../components/ShopNavigator/ShopNavigator';
import { fetchAllFamilies } from '../Macrosoft_API';
import { Menu, MenuItem } from '@mui/material';
import useMainContext from '../components/Context/useMainContext';
import MenuLatMobile from './MenuLatMobile';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import carritoIcon from './Assets/carrito-de-compras.png';
import LoginModal from '../components/Login/modalLogin';
import AboutUsModal from '../components/AboutUs';
import RegisterModal from '../components/modalRegister';
import MenuAdminContainer from '../components/AdminPanel/MenuAdminContainer';
import ChangePassword from '../components/Login/ChangePassword';
import PreOrderModal from '../components/utils/PreOrderModal';
import perfilIcon from './Assets/perfil.png';
export const Index = () => {
	/* context */
	const {
		userLogged,
		userName,
		setUserLogged,
		setUserName,
		arrayProductShop,
		setRol,
		rol,
		search,
		setSearch,
		sectionSelected,
		setSectionSelected,
		setCodCliente,
		CodCliente,
	} = useMainContext();

	const sectionTexts = ['DAHUA', 'Domotica', 'Ofertas'];
	const [anchorEl, setAnchorEl] = useState(null);
	const [articles, setArticles] = useState([]);
	const [product, setProduct] = useState([]);
	const [categories, setCategories] = useState([]);
	const [openModalRegister, setOpenModalRegister] = useState(false);
	const [openModalLogin, setOpenModalLogin] = useState(false);
	const [openModalPreOrder, setOpenModalPreOrder] = useState(false);
	const [aboutUs, setAboutUs] = useState(false);
	const [openModalChangePassword, setopenModalChangePassword] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleLogOut = () => {
		setRol(null);
		setUserLogged(false);
		setUserName('');
		setCodCliente('');
		LogoClick();
		sessionStorage.setItem('UserLogged', false);
		sessionStorage.setItem('CodCliente', '');
		sessionStorage.clear();
	};
	function LogoClick() {
		setSectionSelected('Carrousel');
		setSearch('');
	}
	const handleGetArticles = async () => {
		try {
			const response = await fetchAllFamilies();
			console.log('respuesta desde el fetch:', response);
			if (response?.data) {
				setArticles(response.data);
			}
		} catch (error) {
			console.log('hubo un error en el fetch:', error);
		}
	};
	useEffect(() => {
		handleGetArticles();
	}, []);

	const handleAboutUs = () => {
		setAboutUs(true);
	};
	useEffect(() => {
		console.log('userLogged', CodCliente);
	}, [CodCliente]);
	return (
		<>
			<Grid
				container
				// spacing={1}
				className='---INDEX---'
				sx={{
					minWidth: '375px',
				}}
			>
				<Grid
					className='---HEADER-MOVIL---'
					item
					sx={{
						display: { xs: 'flex', sm: 'none' },
						// padding: '1%',
						// marginLeft: '5%',
						margin: '0 10px',
						justifyContent: 'space-between',
						width: '100vw',
					}}
				>
					<MenuLatMobile />
					<Grid
						sx={{
							display: 'flex',
							gap: 2,
							margin: '3% 5%',
						}}
					>
						<Button
							id='about_us_link'
							sx={{
								marginLeft: '-1%',
								fontFamily: [
									'Montserrat',
									'system-ui',
									'-apple-system',
									'BlinkMacSystemFont',
									'"Segoe UI"',
									'sans-serif',
								].join(','),
							}}
							onClick={handleAboutUs}
						>
							¿Quiénes Somos?
						</Button>
						{/* <ShoppingCartIcon fontSize='large' /> */}
						<Badge
							badgeContent={arrayProductShop?.length}
							color='error'
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
						>
							<IconButton
								onClick={() => {
									if (arrayProductShop.length > 0) {
										setOpenModalPreOrder(true);
									}
								}}
							>
								<img
									src={carritoIcon}
									alt='Carrito'
									style={{ width: '3rem', height: '3rem' }}
								/>
							</IconButton>
						</Badge>

						{!CodCliente && (
							<>
								<AccountCircleIcon
									onClick={() => {
										setOpenModalLogin(true);
									}}
									sx={{
										fontSize: { xs: '35px', sm: '35px' },
										display: 'flex',
										height: '100%',
										justifyContent: 'center',
									}}
								/>
								<Button
									variant='contained'
									onClick={() => setOpenModalRegister(true)}
									sx={{
										background: '#E21B1B',
										width: { xs: '90px', sm: '50px' },
										maxHeight: '37px',
										fontSize: { xs: '.6rem', sm: '35px' },
										color: 'white',
										'&:hover': {
											background: '#C21807', // Cambia el color al hacer hover
										},
										borderRadius: '4px', // Bordes redondeados
									}}
								>
									REGISTRARME
								</Button>
							</>
						)}
						{CodCliente && (
							<div>
								<IconButton
									sx={{
										width: '4rem', // Ajusta el tamaño total del botón
										height: '4rem',
									}}
									onClick={handleMenuClick}
								>
									<img
										src={perfilIcon}
										alt='Perfil'
										style={{
											width: '3rem',
											height: '3rem',
										}}
									/>
								</IconButton>
								<Menu
									anchorEl={anchorEl}
									open={Boolean(anchorEl)}
									onClose={handleMenuClose}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'center',
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'center',
									}}
								>
									{/* Reemplazar los fragments con un renderizado condicional directo */}
									{!userLogged && (
										<MenuItem
											onClick={() => {
												setOpenModalLogin(true);
												handleMenuClose();
											}}
										>
											Iniciar Sesión
										</MenuItem>
									)}
									{userLogged && [
										<MenuItem key='username'>{userName}</MenuItem>,
										<MenuItem
											key='change-password'
											onClick={() => {
												setopenModalChangePassword(true);
												handleMenuClose();
											}}
										>
											Cambiar mi Contraseña
										</MenuItem>,
										<MenuItem
											key='logout'
											onClick={() => {
												handleLogOut();
												handleMenuClose();
											}}
										>
											Cerrar Sesión
										</MenuItem>,
									]}
								</Menu>
							</div>
						)}
						{userLogged && rol === 'Administrador' && (
							<Grid
								sx={{
									display: 'flex',
									alignContent: 'center',
									alignItems: 'center',
								}}
							>
								<MenuAdminContainer />
								<Typography>Panel Administrador</Typography>
							</Grid>
						)}
					</Grid>
				</Grid>

				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
				>
					<Header id='header_target' />
				</Grid>

				{sectionSelected === 'ShopNavigator' && <ShopNavigator />}
				{sectionSelected === 'Carrousel' && (
					<Grid>
						<Carrousel setSectionSelected={setSectionSelected} />
					</Grid>
				)}

				<Grid
					className='---PRODUCTOS-DESTACADOS---'
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					sx={{
						color: 'black',
						py: 9,
						mt: '5%',
						boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1)',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
						backgroundPosition: 'bottom',
						marginTop: {
							xs: '3rem',
							sm: '2rem',
							md: '3rem',
							lg: '1%',
						},
					}}
				>
					<Typography
						variant='h1'
						sx={{ color: 'black', fontSize: '2.5rem', marginTop: '50px' }}
					>
						¡Productos Destacados!
					</Typography>
					<Typography
						level='h4'
						color='danger'
						sx={{ mt: 1, fontWeight: 'xl' }}
					>
						{sectionTexts[0]}
					</Typography>
					<ProductsCatalog
						search='DAHUA'
						listaPrecio='1'
					/>
				</Grid>

				<Grid
					className='---SECTION-CONTAINER---'
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					sx={{ marginBottom: '2rem' }}
				>
					<Section />
				</Grid>
				<Divider />
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
				>
					<SectionAliados />
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
				>
					<ContactFooter />
				</Grid>
			</Grid>
			<RegisterModal
				openModalRegister={openModalRegister}
				setopenModalRegister={setOpenModalRegister}
			/>
			<LoginModal
				openModalLogin={openModalLogin}
				setopenModalLogin={setOpenModalLogin}
			/>
			<ChangePassword
				openModalChangePassword={openModalChangePassword}
				setopenModalChangePassword={setopenModalChangePassword}
			></ChangePassword>
			<PreOrderModal
				open={openModalPreOrder}
				handleClose={() => {
					setOpenModalPreOrder(false);
				}}
			/>
			<AboutUsModal
				aboutUs={aboutUs}
				setAboutUs={setAboutUs}
			></AboutUsModal>
			{loading && (
				<Box
					sx={{
						position: 'fixed',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						bgcolor: 'rgba(0, 0, 0, 0.5)',
						zIndex: 1300,
					}}
				>
					<CircularProgress color='primary' />
				</Box>
			)}
		</>
	);
};
