import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

// Función auxiliar para obtener el token
const getToken = () => {
	const token = sessionStorage.getItem('tokenJar');
	if (!token) {
		throw new Error('No se encontró el token de autenticación');
	}
	return token;
};

export const fetchLoginUser = async (data) => {
	let url = `${apiUrl}/loginJAR`;
	try {
		const response = await axios.post(url, data);
		return response;
	} catch (error) {
		throw error;
	}
};


export const fetchChangePassword = async (data) => {
	try {
		
		let url = `${apiUrl}/changePassword`;
		const response = await axios.patch(
			url,
			data, 
		
		  );
		return response;
	} catch (error) {
		throw error;
	}
};

export const fetchForgetPassword = async (data) => {
	try {
		let url = `${apiUrl}/forgetPassword`;
		const response = await axios.patch(
			url,
			data		
		  );
		return response;
	} catch (error) {
		throw error;
	}
};