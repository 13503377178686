import * as React from 'react';
import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { ProductCard } from '../ProductCard';
import useMainContext from '../Context/useMainContext';

export const ShopMain = () => {
	const { articles } = useMainContext();
	useEffect(() => {
		console.log('data recibida en ShopMain ', articles);
	}, [articles]);

	return (
		<>
			<Grid
				className='---ShopMain.js---'
				container
				sx={{
					height: '100%',
					display: 'flex',
					justifyContent: 'center',
					paddingBottom: '6rem',
					minHeight: '100vh',
				}}
			>
				{articles?.map((item) => {
					// console.log('item', item)
					return (
						<ProductCard
							key={item.CodArticulo}
							product={item}
						/>
					);
				})}
			</Grid>
		</>
	);
};
