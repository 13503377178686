import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
// import { autoPlay } from 'react-swipeable-views-utils';
// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function CarrouselMUI({ images, size }) {
	const theme = useTheme();
	const [activeStep, setActiveStep] = useState(0);
	const maxSteps = images.length;

	// 🎯 AutoPlay manual con useEffect
	useEffect(() => {
		if (maxSteps > 1) {
			const interval = setInterval(() => {
				setActiveStep((prevStep) => (prevStep + 1) % maxSteps);
			}, 3000); // Cambia cada 3 segundos

			return () => clearInterval(interval); // Limpia el intervalo al desmontar
		}
	}, [maxSteps]); // Se ejecuta cuando cambia el número de imágenes

	// Evita problemas si no hay imágenes
	useEffect(() => {
		if (images.length === 0) {
			setActiveStep(0);
		}
	}, [images]);

	const handleNext = () => {
		if (activeStep < maxSteps - 1) setActiveStep((prev) => prev + 1);
	};

	const handleBack = () => {
		if (activeStep > 0) setActiveStep((prev) => prev - 1);
	};

	const handleStepChange = (step) => {
		if (step >= 0 && step < maxSteps) {
			setActiveStep(step);
		}
	};

	return (
		<Box sx={{ width: '95%', flexGrow: 1, margin: '0 auto' }}>
			<Paper
				className='---carrouselMUI---'
				square
				elevation={0}
				sx={{
					display: 'flex',
					alignItems: 'center',
					height: 20,
					pl: 2,
					bgcolor: 'background.default',
				}}
			></Paper>
			<SwipeableViews
				axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
				index={activeStep}
				onChangeIndex={handleStepChange}
				enableMouseEvents
			>
				{images.map((step, index) => (
					<div key={step.label}>
						{Math.abs(activeStep - index) <= 2 ? (
							<Box
								component='img'
								sx={{
									height: size?.height,
									display: 'flex',
									width: size?.width,
									overflow: 'hidden',
									justifyContent: 'center',
								}}
								src={step.imgPath}
								alt={step.label}
							/>
						) : null}
					</div>
				))}
			</SwipeableViews>
			<MobileStepper
				steps={maxSteps}
				position='static'
				activeStep={activeStep}
				nextButton={
					<Button
						size='small'
						onClick={handleNext}
						// disabled={activeStep === maxSteps - 1}
						disabled={activeStep === maxSteps - 1 || maxSteps === 0}
					>
						{theme.direction === 'rtl' ? (
							<KeyboardArrowLeft />
						) : (
							<KeyboardArrowRight />
						)}
					</Button>
				}
				backButton={
					<Button
						size='small'
						onClick={handleBack}
						// disabled={activeStep === maxSteps - 1}
						disabled={activeStep === maxSteps - 1 || maxSteps === 0}
					>
						{theme.direction === 'rtl' ? (
							<KeyboardArrowRight />
						) : (
							<KeyboardArrowLeft />
						)}
					</Button>
				}
			/>
		</Box>
	);
}

export default CarrouselMUI;
