import { Box, Grid, Modal, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UserTable from './UserTable';
import PersonIcon from '@mui/icons-material/Person';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '100%',
	height: '100%',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	overflowY: 'auto',
	p: 4,
	padding: 0,
	borderRadius: '10px',
};

export default function UserContainer({ openModal, handleClose }) {
	return (
		<div>
			<Modal
				open={openModal}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={style}>
					<Grid container>
						<Grid
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								backgroundColor: '#303030',
								width: '100%',
								padding: 1,
							}}
						>
							<Typography
								variant='h5'
								sx={{
									color: 'white',
									display: 'flex',
									paddingTop: '2px',
									lineHeight: '1.5',
									gap: 1,
								}}
							>
								<PersonIcon sx={{ fontSize: 30 }} /> Gestion de Usuarios
							</Typography>
							<IconButton
								edge='start'
								color='inherit'
								aria-label='menu'
								onClick={handleClose}
							>
								<CloseIcon sx={{ color: 'white' }} />
							</IconButton>
						</Grid>
					</Grid>
					<Grid sx={{ marginTop: 1, padding: 1 }}>
						<UserTable />
					</Grid>
				</Box>
			</Modal>
		</div>
	);
}
