import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

// export const getToken = async () => {
// 	const data = {
// 		// user: apiUser,
// 		// pass: apiPASSWORD,
// 		user: 'lowend',
// 		pass: 'lowend$.53',
// 	};
// 	try {
// 		const response = await axios.post(`${apiUrl}/login`, data, {
// 			headers: {
// 				'Content-Type': 'application/json',
// 			},
// 		});

// 		return response.data.token;
// 	} catch (error) {
// 		console.log('Hubo un error:', error);
// 		throw error;
// 	}
// };

export const getToken = async () => {
	try {
		const response = await axios.get(`${apiUrl}/login`, {
			headers: {
				'Content-Type': 'application/json',
			},
		});
		console.log('tokennnMAC', response);

		return response.data.token;
	} catch (error) {
		console.log('Hubo un error:', error);
		throw error;
	}
};

// add fetchAllClients

export const fetchAllFamilies = async () => {
	//PRIMERA FAMILIA
	const token = await getToken();
	const params = new URLSearchParams();
	params.append('token', token);
	const url = `${apiUrl}/getAllFamilies?${params}`;
	try {
		const response = await axios.get(url, {
			headers: {
				'Content-Type': 'application/json',
			},
		});
		return response.data;
	} catch (error) {
		console.log('Hubo un error:', error);
		throw error;
	}
};

//change to fetchAllSubFamilies
export const fetchSubCategories = async (categorie) => {
	//SEGUNDA FAMILIA
	const token = await getToken();
	console.log('categoria recibida', categorie);
	const params = new URLSearchParams();
	params.append('token', token);
	params.append('subFamily', categorie.categories);
	const url = `${apiUrl}/getAllSubFamilies?${params}`;
	try {
		const response = await axios.get(url, {
			headers: {
				'Content-Type': 'application/json',
			},
		});
		return response.data;
	} catch (error) {
		console.log('hubo un error:', error);
		throw error;
	}
};
export const fetchAllSubCategoryByThirdFamily = async (data) => {
	const { subFamily, family, page } = data;
	const token = await getToken();
	const params = new URLSearchParams();
	params.append('token', token);
	params.append('page', page);
	params.append('subFamily', subFamily);
	params.append('family', family);
	const url = `${apiUrl}/getSubCategoryByThirdFamily?${params}`;

	try {
		const response = await axios.get(url, {
			headers: {
				'Content-Type': 'application/json',
			},
		});
		return response.data;
	} catch (error) {
		console.log('Hubo un error:', error);
		throw error;
	}
};

// Obtener get con todos los filtros
export const fetchArticles = async ({
	description,
	family,
	subFamily,
	CodCliente,
	CodStock,
}) => {
	console.log('subFamily recibida aca ---> ', subFamily);
	const token = await getToken();
	// const params = new URLSearchParams();

	// Validación y limpieza de CodCliente
	let codClienteValue;
	if (
		CodCliente === '' ||
		CodCliente === null ||
		CodCliente === undefined ||
		CodCliente === false
	) {
		codClienteValue = 0;
	} else {
		// Remover cualquier comilla y convertir a número
		codClienteValue = parseInt(CodCliente.toString().replace(/['"]+/g, ''));
		if (isNaN(codClienteValue)) {
			codClienteValue = '-';
		}
	}

	// params.append('token', token);
	// params.append('description', description || '-');
	// params.append('family', family || '-');
	// params.append('group', subFamily);
	// params.append('CodCliente', codClienteValue);
	// params.append('CodStock', CodStock || '-');

	const url = `${apiUrl}/getCompleteArticles?`;

	try {
		const response = await axios.post(
			url,
			{
				token: token,
				description: '-',
				CodCliente: codClienteValue,
				CodStock: CodStock ? CodStock : '-',
				family: family ? family : '-',
				group: subFamily ? subFamily : '-',
			},
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);
		// const response = await axios.get(url, {
		// 	headers: {
		// 		'Content-Type': 'application/json',
		// 	},
		// });

		if (!response.data) {
			throw new Error('No data received from server');
		}
		console.log('RESPONSE FETCH', response.data.data);
		return response.data.data || [];
	} catch (error) {
		console.error('Error en fetchArticles:', error.response || error);
		throw error;
	}
};

export const fetchArticlesByFilter = async ({
	search,
	CodCliente,
	page = 1,
}) => {
	const token = await getToken();

	const params = new URLSearchParams();
	params.append('token', token);
	params.append('search', search);
	params.append('CodCliente', CodCliente);
	params.append('page', page);

	const url = `${apiUrl}/getArticleByFilter?${params.toString()}`;

	try {
		const response = await axios.get(url, {
			headers: {
				'Content-Type': 'application/json',
			},
		});
		return response.data.data;
	} catch (error) {
		console.log('Hubo un error:', error);
		throw error;
	}
};
export const fetchAllClientsApi = async (page) => {
	const token = await getToken();
	const params = new URLSearchParams();
	params.append('token', token);
	params.append('page', page);

	const url = `${apiUrl}/getClients?${params}`;
	try {
		const response = await axios.get(url, {
			headers: {
				'Content-Type': 'application/json',
			},
		});
		return response;
	} catch (error) {
		console.log('hubo un error', error);
	}
};
export const fetchImages = async (name) => {
	const url = `${apiUrl}/getImages?name=${name}`;
	try {
		const response = await axios.get(url, {
			headers: {
				'Content-Type': 'image/jpeg',
			},
			responseType: 'blob',
		});

		const imageUrl = URL.createObjectURL(response.data);
		return imageUrl;
	} catch (error) {
		// console.log('Hubo un error NO IMAGES', error);
		// console.error('Error al obtener imagen:', {
		// 	message: error.message,
		// 	code: error.code,
		// 	status: error.response?.status,
		// 	data: error.response?.data,
		// });
		// throw error; // Re-lanza para que quien llame pueda manejar
	}
};

// solo trae el stock de un articulo
export const getArticlesWithStock = async (token, CodCliente, Nombre) => {
	try {
		const response = await axios.get(`${apiUrl}/getArticlesWithStock`, {
			params: {
				token,
				CodCliente,
				Nombre,
			},
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return response.data;
	} catch (error) {
		console.error('Error al obtener el stock:', error);
		throw error;
	}
};

export const getCompleteArticles = async (token, CodCliente, family) => {
	try {
		const response = await axios.post(
			`${apiUrl}}/getCompleteArticles`,
			{
				token,
				description: '-',
				CodCliente,
				CodStock: '-',
				family,
				group: '-',
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error('Error al obtener los articulos:', error);
		throw error;
	}
};

// FUNCION PARA BUSCAR POR TEXTO QUE APUNTA A /getArticles en BACKEND
export const getArticlesByText = async ({
	search,
	family,
	subFamily,
	CodCliente,
	CodStock,
}) => {
	const token = await getToken();
	const params = new URLSearchParams();

	// Validación y limpieza de CodCliente
	let codClienteValue;
	if (
		CodCliente === '' ||
		CodCliente === null ||
		CodCliente === undefined ||
		CodCliente === false
	) {
		codClienteValue = 101;
	} else {
		// Remover cualquier comilla y convertir a número
		codClienteValue = parseInt(CodCliente.toString().replace(/['"]+/g, ''));
		if (isNaN(codClienteValue)) {
			codClienteValue = '-';
		}
	}

	params.append('token', token);
	params.append('description', search || '-');
	params.append('family', family || '-');
	params.append('group', subFamily || '-');
	params.append('CodCliente', codClienteValue);
	params.append('CodStock', CodStock || '-');

	const url = `${apiUrl}/getArticles?${params}`;
	console.log('URL de la petición:', url, 'CodCliente:', codClienteValue);

	try {
		const response = await axios.get(url, {
			headers: {
				'Content-Type': 'application/json',
			},
		});

		if (!response.data) {
			throw new Error('No data received from server');
		}
		console.log('RESPONSE FETCH', response.data.data);
		return response.data.data || [];
	} catch (error) {
		console.error('Error en fetchArticles:', error.response || error);
		throw error;
	}
};
