import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Index } from "./erebil";
import { Provider as MainProvider } from "./components/Context/MainContext";
import { Provider as CategoriesProvider } from "./components/Context/CategoriesContext";

function App() {
  return (
    <div className="App">
      <GoogleOAuthProvider clientId="960710479294-vn4an88b2puhbpbjcpg6g273c9l1amu4.apps.googleusercontent.com">
        <MainProvider>
          <CategoriesProvider>
            <Router>
              <Routes>
                <Route path="/" element={<Index />} />
              </Routes>
            </Router>
          </CategoriesProvider>
        </MainProvider>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
