import { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { Box, Button, Grid, TextField, Snackbar } from '@mui/material';
import Modal from '@mui/material/Modal';
import useMainContext from '../Context/useMainContext';
import '../../erebil/Assets/stylesFormRegister.css';
import { useForm } from 'react-hook-form';
import { fetchChangePassword } from '../controller/authController';
import Alert from '@mui/material/Alert';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';

import Backdrop from '@mui/material/Backdrop';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	minWidth: '337.5px',
	width: {
		xs: '90%',
		sm: '60%',
		md: '60%',
		lg: '40%',
	},
	// height: {
	// 	xs: 'min-content',
	// 	sm: '60%',
	// 	md: '40%',
	// },
	bgcolor: 'white',
	border: '2px solid #000',
	boxShadow: 24,
	// p: 4,
	borderRadius: '10px',
};

export default function ChangePassword({
	openModalChangePassword,
	setopenModalChangePassword,
	actualpass,
}) {
	const {
		// email,
		// setEmail,
		setUserName,
		// userLogged,
		// setUserLogged,
		setRol,
		// tokenJar,
		setTokenJar,
		setCodCliente,
		// FirstLogin,
		setFirstLogin,
		// setSearch,
	} = useMainContext();

	const [showOldPassword, setShowOldPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);
	const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
	const handleClickShowConfirmPassword = () =>
		setShowConfirmPassword(!showConfirmPassword);

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const [open, setOpen] = useState(false);
	const [password, setPassword] = useState('');
	const [oldPassword, setOldPassword] = useState('');
	const [confirmEmail, setConfirmEmail] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [successSnackbar, setSuccessSnackbar] = useState(false);
	const [errorSnackbar, setErrorSnackbar] = useState(false);
	const handleOpen = () => setOpen(true);

	const handleClose = () => {
		handleClearAlert();
		setConfirmEmail('');
		setOldPassword('');
		setNewPassword('');
		setConfirmPassword('');
		setSuccessSnackbar(false);
		setErrorSnackbar(false);
		setopenModalChangePassword(false);
	};
	const [modalAlert, setModalAlert] = useState(false);
	const [text, setText] = useState('');
	const [handleAlert, setHandleAlert] = useState({
		display: false,
		text: '',
		metodo: '',
	});
	const handleClearAlert = () => {
		setHandleAlert({
			display: false,
			text: '',
			metodo: '',
		});
	};
	const handleChangePassword = async () => {
		const data = {
			oldPassword: oldPassword,
			newPassword: newPassword,
			confirmPassword: confirmPassword,
			email: confirmEmail,
		};

		console.log('data que paso', data);

		try {
			console.log('CHANGE ANTES: ', data);
			const response = await fetchChangePassword(data);
			console.log('CHANGE DESPUES: ', data);
			setSuccessMessage(response.data.message); // Mensaje de éxito desde el backend
			setErrorMessage(''); // Limpiar errores previos
			setSuccessSnackbar(true);
			const token = response.data.token;
			if (!token) {
				throw new Error('No se recibió el token en la respuesta');
			}
			sessionStorage.setItem('tokenJar', token);
			sessionStorage.setItem('UserRol', true);
			const decoded = jwtDecode(token);

			console.log('Información decodificada del token:', decoded);

			setTokenJar(token);
			setUserName(decoded.nombre);
			setCodCliente(decoded.CodCliente);
			setRol(decoded.rol);
			setFirstLogin(false);
			setTimeout(() => {
				handleClose();
			}, 2000);
		} catch (error) {
			if (error.response) {
				let responseText = error?.response?.data?.message;
				let responseStatus = error?.response?.status;
				if (responseStatus === 401 || 404) {
					setHandleAlert({
						display: true,
						text: responseText,
						metodo: 'error',
					});
					setErrorMessage(responseText);
					setErrorSnackbar(true);
				}
			} else if (error.request) {
				// La petición fue hecha pero no se recibió respuesta
				console.log('Error de petición:', error.request);
			} else {
				// Algo salió mal al configurar la petición
				console.log('Error:', error.message);
			}
		}
	};

	const [typeList, setTypeList] = useState('Seleccione tipo de Usuario');
	const handleEnter = (e) => {
		if (e.key === 'Enter') {
			handleChangePassword();
		}
	};
	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const handleOpenModalAlert = () => {
		setText('Contraseña actualizada con éxito');
		setModalAlert(true);
		handleClose();
	};

	return (
		<div>
			<Modal
				open={openModalChangePassword}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				slots={{
					backdrop: (props) => (
						<Backdrop
							{...props}
							onClick={(e) => e.stopPropagation()}
						/>
					),
				}}
			>
				<Box sx={style}>
					<Grid container>
						<Grid className='headerFormRegister'>
							<h2 className='tituloFormRegister'>Cambiar mi Contraseña</h2>
						</Grid>
						<Grid className='containerFormRegister'>
							<Grid className='containerInputForm'>
								<TextField
									id='campo6'
									label='Confirmar email'
									type='email'
									value={confirmEmail}
									fullWidth
									margin='normal'
									{...register('email', {
										required: 'La confirmación de email es obligatoria',
										onChange: (e) => {
											setConfirmEmail(e.target.value);
										},
									})}
									error={!!errors.confirmEmail}
									helperText={errors.confirmEmail?.message}
									onKeyDown={handleEnter}
								/>
								<TextField
									id='campo3'
									label={actualpass}
									type={showOldPassword ? 'text' : 'password'}
									value={oldPassword}
									fullWidth
									margin='normal'
									{...register('oldPassword', {
										required: 'La vieja contraseña es obligatoria',
										onChange: (e) => {
											setOldPassword(e.target.value);
										},
									})}
									error={!!errors.oldPassword}
									helperText={errors.oldPassword?.message}
									onKeyDown={handleEnter}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													aria-label='toggle password visibility'
													onClick={handleClickShowOldPassword}
													onMouseDown={handleMouseDownPassword}
												>
													{showOldPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>

								<TextField
									id='campo4'
									label='Nueva Contraseña'
									type={showNewPassword ? 'text' : 'password'}
									value={newPassword}
									fullWidth
									margin='normal'
									{...register('newPassword', {
										required: 'La nueva contraseña es obligatoria',
										onChange: (e) => {
											setNewPassword(e.target.value);
										},
									})}
									error={!!errors.newPassword}
									helperText={errors.newPassword?.message}
									onKeyDown={handleEnter}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													aria-label='toggle password visibility'
													onClick={handleClickShowNewPassword}
													onMouseDown={handleMouseDownPassword}
												>
													{showNewPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								<TextField
									id='campo5'
									label='Confirmar Nueva Contraseña'
									type={showConfirmPassword ? 'text' : 'password'}
									value={confirmPassword}
									fullWidth
									margin='normal'
									{...register('confirmPassword', {
										required: 'Confirmar la contraseña es obligatorio',
										onChange: (e) => {
											setConfirmPassword(e.target.value);
										},
									})}
									error={!!errors.confirmPassword}
									helperText={errors.confirmPassword?.message}
									onKeyDown={handleEnter}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													aria-label='toggle password visibility'
													onClick={handleClickShowConfirmPassword}
													onMouseDown={handleMouseDownPassword}
												>
													{showConfirmPassword ? (
														<VisibilityOff />
													) : (
														<Visibility />
													)}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
						</Grid>
						<Grid
							container
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Grid
								className='--BOTONES--'
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									paddingBottom: '1rem',
									gap: '1rem',
								}}
							>
								<Button
									onClick={handleSubmit(handleChangePassword)}
									variant='outlined'
									sx={{
										background: 'white',
										color: '#E21B1B',
										border: '1px solid red',
										minWidth: '130px',
										'&:hover': {
											background: '#E21B1B',

											color: 'white',
										},
									}}
								>
									Confirmar
								</Button>

								<Button
									onClick={handleClose}
									variant='outlined'
									sx={{
										background: 'white',
										color: '#E21B1B',
										border: '1px solid red',
										minWidth: '130px',
										'&:hover': {
											background: '#E21B1B',
											color: 'white',
										},
									}}
								>
									Cancelar
								</Button>
							</Grid>
						</Grid>
						<Grid
							className='ALERT-DIV'
							sx={{
								display: 'flex',
								width: '100%',
								justifyContent: 'center',
							}}
						>
							<Snackbar
								open={successSnackbar}
								onClose={() => setSuccessSnackbar(false)}
							>
								<Alert
									onClose={() => setSuccessSnackbar(false)}
									severity='success'
									sx={{
										width: '100%',
										display: 'flex',
									}}
								>
									{successMessage}
								</Alert>
							</Snackbar>

							<Snackbar
								sx={{
									position: 'unset',
									display: 'flex',
									justifyContent: 'center',
								}}
								open={errorSnackbar}
								onClose={() => setErrorSnackbar(false)}
							>
								<Alert
									onClose={() => setErrorSnackbar(false)}
									severity='error'
									sx={{
										// width: '100%',
										display: 'flex',
									}}
								>
									{errorMessage}
								</Alert>
							</Snackbar>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</div>
	);
}
