import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const fetchSendPurchase = async (data) => {
  const url = `${apiUrl}/sendPurchase`;
  data.option = "resumenCompra";
  try {
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    console.log("hubo un error al registrar usuario: ", error);
    throw error;
  }
};
