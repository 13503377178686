import * as React from 'react';
import { Alert, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import '../../erebil/Assets/stylesFormRegister.css';
import { height } from '@fortawesome/free-solid-svg-icons/fa0';
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	height: 200,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
	borderRadius: '10px',
};

export default function ModalAlert({ open, handleClose, text }) {
	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={style}>
					<Grid className='headerFormRegister'>
						<h2 className='tituloFormRegister'>INFORMACION</h2>
					</Grid>
					<Grid
						sx={{
							display: 'grid',
							justifyItems: 'center',
							alignItems: 'center',
						}}
					>
						<Alert severity='success'>{text}</Alert>
					</Grid>
					<Grid sx={{ marginTop: '20%' }}>
						<Button onClick={handleClose}>Aceptar</Button>
					</Grid>
				</Box>
			</Modal>
		</div>
	);
}
